/* Activity log loading state */
/* .logContainer.isLoading {
    position: relative;
    overflow: hidden;
} */

.logContainer.isLoading::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(90deg, transparent, #3b82f6, transparent);
  animation: shimmerLoading 2s linear infinite;
  background-size: 200% 100%;
}

.logContainer.isLoading::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(90deg, transparent, rgba(191, 219, 254, 0.1), transparent);
  animation: shimmerLoading 2s linear infinite;
  background-size: 200% 100%;
}

/* Log entry loading animation */
.logEntry {
  position: relative;
  overflow: hidden;
}

.logEntry.isNew {
  animation: newLogEntry 0.5s ease-out;
}

.logEntry.isNew::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(59, 130, 246, 0.1), transparent);
  animation: slideRight 1s ease-out;
}

@keyframes newLogEntry {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideRight {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

/* Loading sparkles for the log container */
.loadingSparkles {
  position: absolute;
  inset: 0;
  pointer-events: none;
  overflow: hidden;
}

.loadingSparkle {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #3b82f6;
}

.loadingSparkle:nth-child(1) {
  top: 20%;
  left: 10%;
  animation-delay: 0s;
}
.loadingSparkle:nth-child(2) {
  top: 60%;
  right: 10%;
  animation-delay: 0.3s;
}
.loadingSparkle:nth-child(3) {
  bottom: 20%;
  left: 15%;
  animation-delay: 0.6s;
}
.loadingSparkle:nth-child(4) {
  top: 30%;
  right: 15%;
  animation-delay: 0.9s;
}
.loadingSparkle:nth-child(5) {
  bottom: 40%;
  right: 20%;
  animation-delay: 1.2s;
}

/* Button loading state */
.button.isLoading {
  position: relative;
  background: linear-gradient(90deg, rgba(251, 252, 253, 0.95) 0%, rgba(243, 244, 246, 0.9) 25%, #bfdbfe 50%, rgba(243, 244, 246, 0.9) 75%, rgba(251, 252, 253, 0.95) 100%);
  background-size: 200% 100%;
  animation: shimmerLoading 2s linear infinite;
}

.button.isLoading .sparkle {
  animation: sparkleFloat 1.5s infinite;
  opacity: 1;
}

.button.isLoading .sparkleContainer {
  opacity: 1;
}

/* Auto-scrolling log container */
.logContainer {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 500px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(249, 250, 251, 0.9));
  border: 1.5px solid rgb(215, 227, 231);
  border-radius: 1rem;
  padding: 0.5rem 0.8rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.logContainer h2 {
  font-family: 'Geist SemiBold';
  color: #1f2937;
  font-size: 14px;
  margin-left: 4px;
  margin-bottom: 8px;
  text-align: left;
}

.logContainer svg {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  color: #0968ff;
  animation: bouneAndSpin 4s infinite;
}

.logContainer ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: 'GeistMono Regular';
  font-size: 0.875rem;
  color: #6b7280;
  max-height: 150px;
  overflow-y: auto;
  scroll-behavior: smooth; /* Smooth scrolling */
  scrollbar-width: thin;
  scrollbar-color: #93c5fd transparent;
}

.logContainer ul::-webkit-scrollbar {
  width: 6px;
}

.logContainer ul::-webkit-scrollbar-track {
  background: transparent;
}

.logContainer ul::-webkit-scrollbar-thumb {
  background-color: #93c5fd;
  border-radius: 3px;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bouneAndSpin {
  0%,
  100% {
    transform: translateY(-4px) rotate(0deg);
  }
  50% {
    transform: translateY(-6px) rotate(180deg);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes bounce2 {
  0%,
  100% {
    transform: translateY(-2);
  }
  50% {
    transform: translateY(-6px);
  }
}

@keyframes floatTitle {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

/* Add sparkle effects similar to your device cards */
.sparkleContainer {
  position: absolute;
  inset: -20px;
  pointer-events: none;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.button:not(:disabled):hover .sparkleContainer {
  opacity: 1;
}

.sparkle {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: linear-gradient(135deg, #60a5fa, #3b82f6);
  animation: sparkle 2s infinite;
}

.sparkle:nth-child(1) {
  top: 20%;
  left: 10%;
  animation-delay: 0s;
}
.sparkle:nth-child(2) {
  top: 60%;
  right: 10%;
  animation-delay: 0.5s;
}
.sparkle:nth-child(3) {
  bottom: 20%;
  left: 15%;
  animation-delay: 1s;
}
.sparkle:nth-child(4) {
  top: 30%;
  right: 15%;
  animation-delay: 1.5s;
}

@keyframes sparkle {
  0%,
  100% {
    transform: scale(0) rotate(0deg);
    opacity: 0;
  }
  50% {
    transform: scale(1) rotate(180deg);
    opacity: 0.8;
  }
}
