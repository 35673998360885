.searchContainer {
  position: sticky;
  top: 0;
  z-index: 40;
  padding: 0.5rem;
  /* background: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.8)); */
  /* backdrop-filter: blur(8px); */
  /* margin-bottom: 0.5rem; */
}

.searchWrapper {
  max-width: 800px;
  margin: 0 auto;
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.searchInputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.searchInput {
  width: 100%;
  padding: 0.875rem 2.5rem;
  border-radius: 1rem;
  border: 1.5px solid rgba(215, 227, 231, 0.8);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(249, 250, 251, 0.9));
  font-size: 1rem;
  transition: all 0.3s;
}

.searchInput:focus {
  border-color: #93c5fd;
  box-shadow: 0 0 0 4px rgba(59, 130, 246, 0.1);
  outline: none;
}

.searchIcon {
  position: absolute;
  left: 0.875rem;
  width: 1.25rem;
  height: 1.25rem;
  color: #9ca3af;
  transition: all 0.3s;
}

.searchIcon.active {
  color: #3b82f6;
}

.clearButton {
  position: absolute;
  right: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  background: #e5e7eb;
  border-radius: 50%;
  color: #6b7280;
  cursor: pointer;
  transition: all 0.2s;
  animation: fadeIn 0.2s ease-out;
}

.clearButton:hover {
  background: #d1d5db;
  transform: scale(1.1);
}

.filterButton {
  position: absolute;
  right: 0.875rem;
  background: none;
  border: none;
  color: #6b7280;
  cursor: pointer;
  padding: 0.25rem;
  transition: all 0.2s;
}

.filterButton.active {
  color: #3b82f6;
  transform: rotate(180deg);
}

/* Filters Panel */
.filtersPanel {
  margin-top: 1rem;
  padding: 1rem;
  background: white;
  border-radius: 1rem;
  border: 1.5px solid rgba(215, 227, 231, 0.8);
  animation: slideDown 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.filterSection {
  margin-bottom: 1rem;
}

.filterSection:last-child {
  margin-bottom: 0;
}

.filterLabel {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: all 0.2s;
}

.filterLabel:hover {
  background: #f3f4f6;
}

.filterCheckbox {
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  border: 2px solid #d1d5db;
  transition: all 0.2s;
}

.filterCheckbox:checked {
  border-color: #3b82f6;
  background-color: #3b82f6;
}

.filterIcon {
  width: 1rem;
  height: 1rem;
  color: #6b7280;
}

.filterGroupLabel {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #4b5563;
  margin-bottom: 0.5rem;
}

.categoryFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.categoryButton {
  padding: 0.5rem 1rem;
  border: 1.5px solid #e5e7eb;
  border-radius: 2rem;
  background: none;
  color: #6b7280;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
}

.categoryButton:hover {
  border-color: #93c5fd;
  color: #3b82f6;
  transform: translateY(-1px);
}

.categoryButton.active {
  background: #3b82f6;
  border-color: #3b82f6;
  color: white;
}

/* Active Filters */
.activeFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
  animation: slideDown 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.filterTag {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem 0.75rem;
  background: #eff6ff;
  border-radius: 2rem;
  font-size: 0.875rem;
  color: #3b82f6;
  animation: popIn 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.removeFilter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.125rem;
  border: none;
  background: none;
  color: #3b82f6;
  cursor: pointer;
  transition: all 0.2s;
}

.removeFilter:hover {
  transform: scale(1.2);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes popIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Mobile Optimizations */
@media (max-width: 640px) {
  .searchContainer {
    padding: 0.75rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .searchWrapper.expanded {
    /* position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 50;
    padding: 1rem; */
  }

  .categoryFilters {
    gap: 0.375rem;
  }

  .categoryButton {
    padding: 0.375rem 0.75rem;
    font-size: 0.813rem;
  }
}

/* Focus States */
.searchInput:focus-visible,
.filterButton:focus-visible,
.categoryButton:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px #93c5fd;
}

/* Loading State */
.searchWrapper.loading .searchIcon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
