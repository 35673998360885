.registrationFlow {
  max-width: 600px;
  margin: 0 auto;
  background: white;
  border-radius: 1.5rem;
  padding: 2rem;
  animation: slideUp 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.registerTagHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.backButton,
.closeButton {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: none;
  background: linear-gradient(to bottom, #f9fafb, #f3f4f6);
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backButton:hover,
.closeButton:hover {
  transform: scale(1.1);
  background: linear-gradient(to bottom, #f3f4f6, #e5e7eb);
}

.backIcon,
.closeIcon {
  width: 1.25rem;
  height: 1.25rem;
  color: #6b7280;
}

/* Item Preview */
.registerTagItemPreview {
  text-align: center;
  padding: 1.2rem 0.5rem;
  background: linear-gradient(to bottom, #f9fafb, #f3f4f6);
  border-radius: 1rem;
  margin-bottom: 0.4rem;
}

.registerTagItemPreview h3 {
  margin: 0;
}

.previewIcon {
  width: 4rem;
  height: 4rem;
  margin: 0 auto 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 50%;
  color: #3b82f6;
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.1);
  transition: all 0.3s;
}

.previewIcon.scanning {
  animation: scanPulse 1.5s infinite;
}

.previewIcon img {
  width: 2rem;
  height: 2rem;
}

/* Steps */
.step {
  display: flex;
  gap: 1rem;
  padding: 1.25rem;
  border-radius: 1rem;
  background: linear-gradient(to right, #f9fafb, #f3f4f6);
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  opacity: 0.7;
}

.step.active {
  background: linear-gradient(to right, #eff6ff, #dbeafe);
  opacity: 1;
}

.step.completed {
  background: linear-gradient(to right, #ecfdf5, #d1fae5);
  opacity: 0.8;
}

.stepNumber {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 50%;
  font-weight: 600;
  color: #3b82f6;
}

.stepCheck {
  color: #059669;
  animation: checkPop 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

/* Scanning Status */
.scanningStatus {
  margin-top: 1rem;
}

.statusMessage {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem;
  border-radius: 0.75rem;
  background: white;
  margin-top: 1rem;
}

.scanningRing {
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid #3b82f6;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

.writingProgress {
  height: 2px;
  background: #3b82f6;
  width: 100%;
  animation: progress 1.5s linear;
}

/* Note Section */
.noteSection {
  margin-top: 1rem;
}

.addNoteButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  border: none;
  background: white;
  border-radius: 0.75rem;
  color: #3b82f6;
  cursor: pointer;
  transition: all 0.2s;
}

.addNoteButton:hover {
  background: #eff6ff;
  transform: translateY(-2px);
}

.noteInput {
  margin-top: 0.5rem;
  animation: slideDown 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.noteTextarea {
  width: 100%;
  min-height: 80px;
  padding: 0.75rem;
  border-radius: 0.75rem;
  border: 1.5px solid #e5e7eb;
  background: white;
  resize: vertical;
  transition: all 0.2s;
}

.noteTextarea:focus {
  border-color: #93c5fd;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  outline: none;
}

/* Final Action */
.finalAction {
  margin-top: 2rem;
  text-align: center;
}

.completeButton {
  padding: 1rem 2rem;
  background: linear-gradient(to bottom, #059669, #047857);
  color: white;
  border: none;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin: 0 auto;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.completeButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(5, 150, 105, 0.2);
}

/* Animations */
@keyframes scanPulse {
  0%,
  100% {
    transform: scale(1);
    box-shadow: 0 4px 12px rgba(59, 130, 246, 0.1);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 4px 20px rgba(59, 130, 246, 0.2);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes checkPop {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Error State */
.statusMessage.error {
  background: #fef2f2;
  color: #dc2626;
}

.errorIcon {
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.retryButton {
  padding: 0.5rem 1rem;
  background: #dc2626;
  color: white;
  border: none;
  border-radius: 0.5rem;
  margin-left: auto;
  cursor: pointer;
  transition: all 0.2s;
}

.retryButton:hover {
  background: #b91c1c;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@media (max-width: 640px) {
  .registrationFlow {
    padding: 1.5rem;
    margin: 0.5rem;
    border-radius: 1rem;
  }

  .flowHeader h2 {
    font-size: 1.25rem;
  }

  .step {
    padding: 1rem;
  }

  .previewIcon {
    width: 3rem;
    height: 3rem;
  }

  .completeButton {
    width: 100%;
    justify-content: center;
  }
}

/* Loading States */
.loadingOverlay {
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
  backdrop-filter: blur(4px);
}

/* Success Animation */
.successAnimation {
  position: fixed;
  inset: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  animation: fadeIn 0.3s ease-out;
}

.successCircle {
  width: 120px;
  height: 120px;
  background: linear-gradient(to bottom right, #059669, #34d399);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: successPop 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.successIcon {
  width: 60px;
  height: 60px;
  color: white;
  animation: successDrawCheck 0.8s ease-out forwards;
  opacity: 0;
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
}

@keyframes successPop {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes successDrawCheck {
  0% {
    opacity: 1;
    stroke-dashoffset: 100;
  }
  100% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
}

/* Nintendo-style button press effect */
.scanButton:active,
.completeButton:active,
.addNoteButton:active {
  transform: scale(0.95);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Hover trail effect */
.step.active::after {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  background: radial-gradient(circle, rgba(59, 130, 246, 0.2), transparent 70%);
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  animation: followCursor 0.3s linear;
}

/* Tactile feedback animations */
.scanButton,
.completeButton,
.addNoteButton {
  position: relative;
  overflow: hidden;
}

.buttonRipple {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.7);
  transform: translate(-50%, -50%);
  animation: ripple 0.6s linear;
  pointer-events: none;
}

@keyframes ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0.5;
  }
  100% {
    width: 500px;
    height: 500px;
    opacity: 0;
  }
}

/* Progress indicator animations */
.progressRing {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  transform-origin: 50% 50%;
  transform: rotate(-90deg);
  transition: stroke-dashoffset 0.3s ease;
}

/* Wii-style focus indicators */
.scanButton:focus-visible,
.completeButton:focus-visible,
.addNoteButton:focus-visible {
  outline: none;
}

.scanButton:focus-visible::after,
.completeButton:focus-visible::after,
.addNoteButton:focus-visible::after {
  content: '';
  position: absolute;
  inset: -4px;
  border: 2px solid rgba(59, 130, 246, 0.5);
  border-radius: inherit;
  animation: focusPing 2s cubic-bezier(0, 0, 0.2, 1) infinite;
}

/* Enhanced hover states */
.step:hover .stepNumber {
  transform: rotate(-10deg);
  transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.step.completed:hover .stepCheck {
  animation: bounceCheck 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

@keyframes bounceCheck {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2) rotate(10deg);
  }
}

/* Transition states */
.flowTransition-enter {
  opacity: 0;
  transform: translateY(20px);
}

.flowTransition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.flowTransition-exit {
  opacity: 1;
  transform: translateY(0);
}

.flowTransition-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

/* NFC scanning simulation effect */
.scanningWave {
  position: absolute;
  inset: -20px;
  border: 2px solid #3b82f6;
  border-radius: 50%;
  animation: scanWave 2s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes scanWave {
  0% {
    transform: scale(0.8);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.successOverlay {
  position: fixed;
  inset: 0;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  animation: fadeIn 0.3s ease-out;
  backdrop-filter: blur(8px);
}

.successContent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

/* Enhanced success circle animation */
.successCircle {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: linear-gradient(135deg, #34d399 0%, #059669 100%);
  animation: bounceIn 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.successInner {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: rotate 20s linear infinite;
}

.successIcon {
  width: 60px;
  height: 60px;
  color: white;
  animation: popAndSpin 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
}

/* Orbiting sparkles */
.orbitingSparkle {
  position: absolute;
  width: 24px;
  height: 24px;
  color: #fcd34d;
  animation: orbit 4s linear infinite;
  animation-delay: calc(var(--index) * -1s);
}

/* Bouncing text animation */
.successMessage {
  text-align: center;
}

.bounceText {
  font-size: 2rem;
  color: #059669;
  margin: 0;
}

.bounceText span {
  display: inline-block;
  animation: bounceIn 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
  opacity: 0;
  animation-delay: calc(var(--delay) + 0.3s);
}

/* Confetti */
.confetti {
  position: absolute;
  width: 10px;
  height: 10px;
  background: linear-gradient(135deg, #60a5fa, #3b82f6);
  border-radius: 2px;
  animation: confettiFall 3s linear forwards;
  animation-delay: var(--delay);
  opacity: 0;
}

/* Stars */
.star {
  position: absolute;
  color: #fcd34d;
  animation: starFloat 2s ease-in-out infinite;
  animation-delay: var(--delay);
}

/* Celebration icons */
.celebrationIcons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.partyIcon {
  color: #3b82f6;
  animation: bounce 1s ease-in-out infinite;
}

.sparkleIcon {
  color: #fcd34d;
  animation: spin 2s linear infinite;
}

/* Animation keyframes */
@keyframes bounceIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes orbit {
  from {
    transform: rotate(0deg) translateX(60px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(60px) rotate(-360deg);
  }
}

@keyframes confettiFall {
  0% {
    transform: translateY(-50px) rotate(0deg) translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateY(var(--distance)) rotate(var(--angle)) translateX(var(--distance));
    opacity: 0;
  }
}

@keyframes starFloat {
  0%,
  100% {
    transform: translate(var(--x), var(--y)) scale(1) rotate(0deg);
  }
  50% {
    transform: translate(calc(var(--x) * 1.2), calc(var(--y) * 1.2)) scale(1.2) rotate(180deg);
  }
}

@keyframes popAndSpin {
  0% {
    transform: scale(0) rotate(-180deg);
  }
  70% {
    transform: scale(1.2) rotate(10deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

/* Rainbow shine effect */
.successCircle::after {
  content: '';
  position: absolute;
  inset: -10px;
  border-radius: 50%;
  background: linear-gradient(45deg, #60a5fa, #34d399, #fcd34d, #60a5fa);
  background-size: 200% 200%;
  filter: blur(10px);
  opacity: 0.5;
  z-index: -1;
  animation: rainbow 2s linear infinite;
}

@keyframes rainbow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}

/* Cuteness overload sparkles */
.sparkleTrail {
  position: absolute;
  pointer-events: none;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
  mix-blend-mode: screen;
}

/* Add more playful animations to the registration flow */
.step.active {
  animation: wiggle 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

@keyframes wiggle {
  0%,
  100% {
    transform: translateX(8px) rotate(0deg);
  }
  25% {
    transform: translateX(8px) rotate(-2deg);
  }
  75% {
    transform: translateX(8px) rotate(2deg);
  }
}

/* Scanning animation enhancement */
.scanningRing::before,
.scanningRing::after {
  content: '';
  position: absolute;
  inset: -4px;
  border: 2px solid #3b82f6;
  border-radius: 50%;
  animation: scanWave 2s infinite cubic-bezier(0.4, 0, 0.2, 1);
  animation-delay: calc(var(--index) * 0.5s);
}

@keyframes scanWave {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

/* Happy bounce for completed items */
.step.completed {
  animation: happyBounce 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

@keyframes happyBounce {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02) rotate(1deg);
  }
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .successCircle {
    width: 100px;
    height: 100px;
  }

  .bounceText {
    font-size: 1.5rem;
  }
}

.successOverlay {
  position: fixed;
  inset: 0;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  animation: fadeIn 0.3s ease-out;
  backdrop-filter: blur(8px);
}

.successContent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

/* Enhanced success circle animation */
.successCircle {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: linear-gradient(135deg, #34d399 0%, #059669 100%);
  animation: bounceIn 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.successInner {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: rotate 20s linear infinite;
}

.successIcon {
  width: 60px;
  height: 60px;
  color: white;
  animation: popAndSpin 0.8s cubic-bezier(0.34, 1.56, 0.64, 1);
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
}

/* Orbiting sparkles */
.orbitingSparkle {
  position: absolute;
  width: 24px;
  height: 24px;
  color: #fcd34d;
  animation: orbit 4s linear infinite;
  animation-delay: calc(var(--index) * -1s);
}

/* Bouncing text animation */
.successMessage {
  text-align: center;
}

.bounceText {
  font-size: 2rem;
  color: #059669;
  margin: 0;
}

.bounceText span {
  display: inline-block;
  animation: bounceIn 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
  opacity: 0;
  animation-delay: calc(var(--delay) + 0.3s);
}

/* Confetti */
.confetti {
  position: absolute;
  width: 10px;
  height: 10px;
  background: linear-gradient(135deg, #60a5fa, #3b82f6);
  border-radius: 2px;
  animation: confettiFall 3s linear forwards;
  animation-delay: var(--delay);
  opacity: 0;
}

/* Stars */
.star {
  position: absolute;
  color: #fcd34d;
  animation: starFloat 2s ease-in-out infinite;
  animation-delay: var(--delay);
}

/* Celebration icons */
.celebrationIcons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.partyIcon {
  color: #3b82f6;
  animation: bounce 1s ease-in-out infinite;
}

.sparkleIcon {
  color: #fcd34d;
  animation: spin 2s linear infinite;
}

/* Animation keyframes */
@keyframes bounceIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes orbit {
  from {
    transform: rotate(0deg) translateX(60px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(60px) rotate(-360deg);
  }
}

@keyframes confettiFall {
  0% {
    transform: translateY(-50px) rotate(0deg) translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateY(var(--distance)) rotate(var(--angle)) translateX(var(--distance));
    opacity: 0;
  }
}

@keyframes starFloat {
  0%,
  100% {
    transform: translate(var(--x), var(--y)) scale(1) rotate(0deg);
  }
  50% {
    transform: translate(calc(var(--x) * 1.2), calc(var(--y) * 1.2)) scale(1.2) rotate(180deg);
  }
}

@keyframes popAndSpin {
  0% {
    transform: scale(0) rotate(-180deg);
  }
  70% {
    transform: scale(1.2) rotate(10deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

/* Rainbow shine effect */
.successCircle::after {
  content: '';
  position: absolute;
  inset: -10px;
  border-radius: 50%;
  background: linear-gradient(45deg, #60a5fa, #34d399, #fcd34d, #60a5fa);
  background-size: 200% 200%;
  filter: blur(10px);
  opacity: 0.5;
  z-index: -1;
  animation: rainbow 2s linear infinite;
}

@keyframes rainbow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}

/* Cuteness overload sparkles */
.sparkleTrail {
  position: absolute;
  pointer-events: none;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
  mix-blend-mode: screen;
}

/* Add more playful animations to the registration flow */
.step.active {
  animation: wiggle 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

@keyframes wiggle {
  0%,
  100% {
    transform: translateX(0px) rotate(0deg);
  }
  25% {
    transform: translateX(0px) rotate(-2deg);
  }
  75% {
    transform: translateX(0px) rotate(2deg);
  }
}

/* Scanning animation enhancement */
.scanningRing::before,
.scanningRing::after {
  content: '';
  position: absolute;
  inset: -4px;
  border: 2px solid #3b82f6;
  border-radius: 50%;
  animation: scanWave 2s infinite cubic-bezier(0.4, 0, 0.2, 1);
  animation-delay: calc(var(--index) * 0.5s);
}

@keyframes scanWave {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

/* Happy bounce for completed items */
.step.completed {
  animation: happyBounce 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

@keyframes happyBounce {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02) rotate(1deg);
  }
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .successCircle {
    width: 100px;
    height: 100px;
  }

  .bounceText {
    font-size: 1.5rem;
  }
}

/* Tag Model Selector Styles */
.tagModelSection {
  margin-top: 0.2rem;
  animation: slideUp 0.3s ease-out;
}

.tagModelSection h3 {
  margin-top: 0;
  font-size: 1rem;
}

.tagModelSelector {
  position: relative;
  width: 100%;
}

.selectorButton {
  width: 100%;
  padding: 0.2rem;
  background: linear-gradient(to bottom, #f9fafb, #f3f4f6);
  border: 1.5px solid rgba(215, 227, 231, 0.8);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.2s;
}

.selectorButton:hover {
  background: linear-gradient(to bottom, #f3f4f6, #e5e7eb);
  border-color: #93c5fd;
}

.selectedModel {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.modelIcon {
  font-size: 1.5rem;
  animation: bounce 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.dropdownIcon {
  transition: transform 0.3s ease;
}

.dropdownIcon.open {
  transform: rotate(180deg);
}

/* Model Dropdown Styles */
.modelDropdown {
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  right: 0;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1.5px solid rgba(215, 227, 231, 0.8);
  z-index: 50;
  animation: slideDown 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.modelOption {
  width: 100%;
  padding: 0.6rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border: none;
  background: none;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
}

.modelOption:not(:last-child) {
  border-bottom: 1px solid #e5e7eb;
}

.modelOption:hover {
  background: linear-gradient(to right, #eff6ff, transparent);
  transform: translateX(4px);
}

.modelInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
}

.modelName {
  font-weight: 500;
  color: #1f2937;
}

.modelDetails {
  font-size: 0.875rem;
  color: #6b7280;
}

/* Model Tooltip Styles */
.modelTooltip {
  position: absolute;
  left: calc(100% + 1rem);
  top: 50%;
  transform: translateY(-50%);
  width: 250px;
  padding: 1rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1.5px solid #e5e7eb;
  z-index: 60;
  animation: fadeIn 0.2s ease-out;
}

.modelTooltip h4 {
  margin: 0 0 0.5rem;
  color: #1f2937;
}

.modelTooltip ul {
  list-style: none;
  padding: 0;
  margin: 0 0 0.5rem;
}

.modelTooltip li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: #6b7280;
  margin-bottom: 0.25rem;
}

.featureIcon {
  width: 1rem;
  height: 1rem;
  color: #3b82f6;
}

/* Animations */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes popIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .modelTooltip {
    position: fixed;
    left: 1rem;
    right: 1rem;
    top: auto;
    bottom: 1rem;
    transform: none;
    width: auto;
  }

  .tagSpecs {
    grid-template-columns: 1fr;
  }
}

#tag-qr-code {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

#tag-qr-code svg {
  width: 100% !important;
  height: 100% !important;
}
