.container {
  min-height: 100vh;
  background-image: repeating-linear-gradient(0deg, rgb(246, 247, 248) 0px, rgb(246, 247, 248) 2.5px, rgb(238, 237, 241) 2.5px, rgb(244, 245, 247) 3.2px, rgb(238, 237, 241) 3.8px, rgb(238, 237, 241) 3.9px);
  padding: 2rem 1rem;
  overflow-x: hidden;
}

/* Tab Navigation */
.tabWrapper {
  max-width: 4xl;
  margin: 0.8rem auto 0.8rem;
  display: flex;
  gap: 1rem;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 1rem;
  backdrop-filter: blur(8px);
}

.tabButton {
  flex: 1;
  padding: 0.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  border: none;
  background: none;
  border-radius: 0.75rem;
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  color: #6b7280;
}

.tabButton.active {
  background: linear-gradient(to bottom, #3b82f6, #2563eb);
  color: white;
  transform: translateY(-2px);
}

.tabIcon {
  width: 1.5rem;
  height: 1.5rem;
}

/* Items Grid */
.itemsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  padding: 1rem;
}

.itemCard {
  position: relative;
  border: none;
  background: none;
  padding: 0.8rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.itemCard::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(120deg, #f5f6f7 0%, #eff0f2 50%, #e9eaec 100%);
  border-radius: 1rem;
  border: 1.5px solid rgba(215, 227, 231, 0.8);
  transition: all 0.3s;
}

.itemCard:hover::before {
  transform: scale(1.02);
  border-color: #93c5fd;
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.15);
}

.itemCardContent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.itemIcon {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #f0f9ff, #e0f2fe);
  border-radius: 50%;
  color: #3b82f6;
}

.itemIcon img {
  width: 3rem;
  height: 3rem;
}

.itemName {
  font-size: 0.8rem;
  font-weight: 600;
  color: #1f2937;
  margin: 0;
}

.itemCategory {
  font-size: 0.875rem;
  color: #6b7280;
  margin: 0;
}

/* NFC Registration Flow */
.registrationFlow {
  max-width: 600px;
  margin: 0 auto;
  background: white;
  border-radius: 1.5rem;
  padding: 2rem;
  animation: slideUp 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.flowHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

/* Recent Scans Table */
.recentScansContainer {
  background: white;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.tableHeader {
  display: grid;
  grid-template-columns: 2fr 1.5fr 1.5fr 1.5fr 1.5fr;
  gap: 1rem;
  padding: 1rem;
  background: linear-gradient(to bottom, #f9fafb, #f3f4f6);
  border-bottom: 1px solid #e5e7eb;
  font-weight: 600;
  color: #374151;
}

.tableRow {
  display: grid;
  grid-template-columns: 2fr 1.5fr 1.5fr 1.5fr 1.5fr;
  gap: 1rem;
  padding: 1rem;
  border-bottom: 1px solid #f3f4f6;
  transition: all 0.2s;
}

.tableRow:hover {
  background: linear-gradient(to right, #f0f9ff, transparent);
}

.cell {
  display: flex;
  align-items: center;
  color: #374151;
}

.cell.mono {
  font-family: 'GeistMono Regular', monospace;
  font-size: 0.875rem;
  color: #6b7280;
}

/* NFC Badge and Register Prompt */
.nfcBadge {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: linear-gradient(to right, #ecfdf5, #d1fae5);
  border-radius: 2rem;
  color: #059669;
  font-size: 0.875rem;
  animation: gentlePulse 2s infinite;
}

.nfcIcon {
  width: 1rem;
  height: 1rem;
}

.registerPrompt {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: linear-gradient(to right, #eff6ff, #dbeafe);
  border-radius: 2rem;
  color: #3b82f6;
  font-size: 0.875rem;
  transition: all 0.3s;
}

.promptIcon {
  width: 1rem;
  height: 1rem;
  transition: transform 0.3s;
}

.itemCard:hover .registerPrompt {
  transform: translateX(4px);
}

.itemCard:hover .promptIcon {
  transform: translateX(4px);
}

/* Scan Button */
.scanButton {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  background: linear-gradient(to bottom, #3b82f6, #2563eb);
  color: white;
  border: none;
  border-radius: 0.75rem;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.scanButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.2);
}

.scanButton:active {
  transform: translateY(1px);
}

.buttonSparkles {
  position: absolute;
  inset: -20px;
  pointer-events: none;
}

.sparkle {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: linear-gradient(135deg, #60a5fa, #3b82f6);
  animation: sparkle 1.5s infinite;
  animation-delay: var(--delay);
}

.sparkle:nth-child(1) {
  top: 20%;
  left: 10%;
}
.sparkle:nth-child(2) {
  top: 60%;
  right: 10%;
}
.sparkle:nth-child(3) {
  bottom: 20%;
  left: 15%;
}
.sparkle:nth-child(4) {
  top: 30%;
  right: 15%;
}

/* Animations */
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes gentlePulse {
  0%,
  100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.8;
    transform: scale(0.95);
  }
}

@keyframes sparkle {
  0%,
  100% {
    opacity: 0;
    transform: scale(0) rotate(0deg);
  }
  50% {
    opacity: 1;
    transform: scale(1) rotate(180deg);
  }
}

/* Item Preview in Registration Flow */
.itemPreview {
  text-align: center;
  padding: 2rem;
  background: linear-gradient(to bottom, #f9fafb, #f3f4f6);
  border-radius: 1rem;
  margin-bottom: 2rem;
}

.previewIcon {
  width: 4rem;
  height: 4rem;
  margin: 0 auto 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 50%;
  color: #3b82f6;
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.1);
  animation: gentleFloat 4s infinite;
}

@keyframes gentleFloat {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 1rem;
    padding-bottom: 0;
    padding-top: 0;
  }

  .itemsGrid {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 0.75rem;
  }

  .tableHeader,
  .tableRow {
    grid-template-columns: 2fr 1.5fr 1fr;
  }

  .headerCell:nth-child(3),
  .cell:nth-child(3),
  .headerCell:nth-child(4),
  .cell:nth-child(4) {
    display: none;
  }

  .registrationFlow {
    padding: 1rem;
    padding-top: 0;
    padding-bottom: 30rem;
  }
}

/* Loading States and Transitions */
.loading {
  opacity: 0.7;
  pointer-events: none;
  position: relative;
}

.loading::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%);
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Focus States for Accessibility */
.tabButton:focus,
.itemCard:focus,
.scanButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.3);
}

/* Custom Selection Color */
::selection {
  background-color: rgba(59, 130, 246, 0.2);
}

/* Live Scans Container */
.liveScanContainer {
  background: white;
  border-radius: 1.5rem;
  overflow: hidden;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

/* Header Styles */
.scanHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  background: linear-gradient(to bottom, #f8fafc, #f1f5f9);
  border-bottom: 1px solid #e2e8f0;
}

.scanHeader h2 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #0f172a;
}

.liveBadge {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: linear-gradient(to right, #fee2e2, #fecaca);
  border-radius: 2rem;
  color: #dc2626;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.liveIndicator {
  width: 8px;
  height: 8px;
  background: #dc2626;
  border-radius: 50%;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

/* Scans List */
.scansList {
  max-height: 600px;
  overflow-y: auto;
  padding: 1rem;
  scroll-behavior: smooth;
}

/* Individual Scan Row */
.scanRow {
  position: relative;
  padding: 1rem;
  border-radius: 1rem;
  margin-bottom: 0.75rem;
  background: #f8fafc;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  transform: translateX(-20px);
  animation: slideIn 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  animation-delay: var(--delay);
}

.scanRow.newScan {
  animation: newScanEntry 0.6s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
  background: linear-gradient(to right, #eff6ff, #dbeafe);
}

.scanContent {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
  align-items: center;
}

/* Scan Icon */
.scanIcon {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.1);
  position: relative;
}

.scanIcon .icon {
  width: 1.25rem;
  height: 1.25rem;
  transition: all 0.3s ease;
}

.icon.pulse {
  animation: iconPulse 1s cubic-bezier(0.4, 0, 0.6, 1) forwards;
}

/* Scan Details */
.scanDetails {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.itemName {
  font-weight: 500;
  color: #0f172a;
  font-size: 0.875rem;
  font-family: 'Geist Regular';
}

.nfcId {
  font-family: 'GeistMono Regular', monospace;
  font-size: 0.75rem;
  color: #64748b;
  letter-spacing: 0.5px;
}

/* Scan Info */
.scanInfo {
  display: flex;
  gap: 1.5rem;
  color: #64748b;
  font-size: 0.75rem;
  align-items: center;
}

.location {
  display: flex;
  align-items: center;
  gap: 0.375rem;
}

.locationIcon {
  width: 1rem;
  height: 1rem;
  color: #3b82f6;
}

/* Animations */
@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes newScanEntry {
  0% {
    opacity: 0;
    transform: translateX(-20px) scale(0.95);
    background: rgba(59, 130, 246, 0.2);
  }
  50% {
    transform: translateX(0) scale(1.1);
    background: rgba(59, 130, 246, 0.1);
  }
  100% {
    opacity: 1;
    transform: translateX(0) scale(1);
    background: rgba(59, 130, 246, 0.05);
  }
}

@keyframes newScanHighlight {
  0% {
    background: rgba(59, 130, 246, 0.2);
    transform: scale(0.98);
  }
  50% {
    background: rgba(59, 130, 246, 0.1);
    transform: scale(1.01);
  }
  100% {
    background: rgba(59, 130, 246, 0.05);
    transform: scale(1);
  }
}

@keyframes iconPulse {
  0% {
    transform: scale(1);
    color: #3b82f6;
  }
  50% {
    transform: scale(1.2);
    color: #2563eb;
  }
  100% {
    transform: scale(1);
    color: #3b82f6;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .liveScanContainer {
    border-radius: 1rem;
  }

  .scanContent {
    grid-template-columns: auto 1fr;
    gap: 0.75rem;
  }

  .scanInfo {
    grid-column: 1 / -1;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-top: 0.5rem;
  }

  .scanIcon {
    width: 2rem;
    height: 2rem;
  }
}

/* Scrollbar Styles */
.scansList::-webkit-scrollbar {
  width: 8px;
}

.scansList::-webkit-scrollbar-track {
  background: #f1f5f9;
  border-radius: 4px;
}

.scansList::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 4px;
}

.scansList::-webkit-scrollbar-thumb:hover {
  background: #94a3b8;
}

/* Tag Details Animation */
.tagDetails {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #e5e7eb;
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* History Item Styles */
.historyIcon {
  width: 2rem;
  height: 2rem;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3b82f6;
  flex-shrink: 0;
}

.historyContent {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.historyLocation {
  font-weight: 500;
  color: #1f2937;
}

.historyDevice {
  color: #6b7280;
  font-size: 0.875rem;
}

.historyTime {
  color: #9ca3af;
  font-size: 0.75rem;
}

/* Empty State Animations */
.emptyState {
  text-align: center;
  padding: 3rem;
  color: #6b7280;
  animation: gentlePulse 2s infinite;
}

@keyframes gentlePulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
}

/* Hover Effects */
.tagCard:hover .expandIcon {
  transform: translateX(4px);
  color: #3b82f6;
}

.expandIcon {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s;
  color: #9ca3af;
}

/* Interactive Elements */
.tagCard:active {
  transform: scale(0.98);
}

.historyItem:hover {
  background: linear-gradient(to right, #eff6ff, #dbeafe);
  transform: translateX(4px);
}

/* Stats Animation */
.statValue {
  display: inline-block;
  animation: countUp 1s cubic-bezier(0.34, 1.56, 0.64, 1);
}

@keyframes countUp {
  from {
    transform: translateY(10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* NFC Scan Waves Effect */
.scanIcon.newScan::before,
.scanIcon.newScan::after {
  content: '';
  position: absolute;
  border: 2px solid #3b82f6;
  border-radius: 50%;
  animation: scanWave 2s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

.scanIcon::after {
  animation-delay: 0.5s;
}

@keyframes scanWave {
  0% {
    inset: -4px;
    opacity: 1;
  }
  100% {
    inset: -12px;
    opacity: 0;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .itemHeader {
    flex-direction: column;
    gap: 1.5rem;
  }

  .itemStats {
    width: 100%;
    justify-content: space-between;
  }

  .scanContent {
    grid-template-columns: auto 1fr;
  }

  .scanInfo {
    grid-column: 1 / -1;
    justify-content: space-between;
  }

  .tagCard {
    padding: 1rem;
  }

  .tagStats {
    flex-direction: column;
    gap: 0.5rem;
  }
}

/* Loading States */
.loadingShimmer {
  background: linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.8) 50%, transparent 100%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

/* Success Indicators */
.successScan {
  position: relative;
}

.successScan::after {
  content: '✓';
  position: absolute;
  right: -8px;
  top: -8px;
  background: #059669;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  animation: popIn 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

@keyframes popIn {
  from {
    transform: scale(0) rotate(-180deg);
  }
  to {
    transform: scale(1) rotate(0);
  }
}

.modelTooltip {
  position: absolute;
  left: calc(100% + 1rem);
  top: 50%;
  transform: translateY(-50%);
  width: 250px;
  padding: 1rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1.5px solid #e5e7eb;
  z-index: 60;
  animation: fadeIn 0.2s ease-out;
}

.modelTooltip h4 {
  margin: 0 0 0.5rem;
  color: #1f2937;
}

.modelTooltip ul {
  list-style: none;
  padding: 0;
  margin: 0 0 0.5rem;
}

.modelTooltip li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: #6b7280;
  margin-bottom: 0.25rem;
}

.featureIcon {
  width: 1rem;
  height: 1rem;
  color: #3b82f6;
}
