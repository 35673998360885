.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 2rem; */
  width: 100%;
  perspective: 1000px;
}

.tagsContainer {
  position: relative;
  height: 8rem;
  width: 100%;
  max-width: 24rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tagWrapper {
  position: absolute;
  /* Center align the wrappers */
  transform: translateX(-50%);
  will-change: transform;
}

.tagWrapper:nth-child(1) {
  left: 30%;
  animation: floatAndSpin 2s infinite;
}

.tagWrapper:nth-child(2) {
  left: 50%;
  animation: bounceAndGlow 1.5s infinite;
}

.tagWrapper:nth-child(3) {
  left: 70%;
  animation: swingAndScale 2s infinite;
}

.tag {
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center;
  will-change: transform;
  /* border: 2px solid rgba(255, 255, 255, 0.3); */
}

/* Different styles for each tag */
/* Enhanced gradients for each tag */
.tag:nth-of-type(1) {
  background: linear-gradient(135deg, #93c5fd, #3b82f6);
  box-shadow:
    0 8px 16px rgba(59, 130, 246, 0.3),
    inset 0 2px 4px rgba(255, 255, 255, 0.3);
}

.tag:nth-of-type(2) {
  background: linear-gradient(135deg, #6ee7b7, #059669);
  box-shadow:
    0 8px 16px rgba(5, 150, 105, 0.3),
    inset 0 2px 4px rgba(255, 255, 255, 0.3);
}

.tag:nth-of-type(3) {
  background: linear-gradient(135deg, #fca5a5, #ef4444);
  box-shadow:
    0 8px 16px rgba(239, 68, 68, 0.3),
    inset 0 2px 4px rgba(255, 255, 255, 0.3);
}

.tagIcon {
  width: 2rem;
  height: 2rem;
  color: white;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.1));
}

.ripple {
  position: absolute;
  inset: 0;
  border-radius: 1rem;
  opacity: 0.2;
}

.sparkle {
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background: white;
  filter: blur(1px);
}

.sparkleTop {
  composes: sparkle;
  top: -0.25rem;
  right: -0.25rem;
  animation: twinkle 1.5s infinite;
}

.sparkleBottom {
  composes: sparkle;
  bottom: -0.25rem;
  left: -0.25rem;
  animation: twinkle 1.5s infinite 0.5s;
}

.sparkleMiddle {
  composes: sparkle;
  top: 50%;
  right: -0.25rem;
  animation: twinkle 1.5s infinite 1s;
}

.loadingText {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 0.375rem;
  transform-style: preserve-3d;
}

.letter {
  display: inline-block;
  color: #3b82f6;
  font-weight: 600;
  font-size: 1.25rem;
  text-shadow: 0 2px 4px rgba(59, 130, 246, 0.2);
  animation: popWave 1.8s infinite;
}

/* Individual letter animations */
.letter:nth-child(odd) {
  animation: popWave 1.8s infinite;
}

.letter:nth-child(even) {
  animation: jumpWave 1.8s infinite;
}

@keyframes floatAndSpin {
  0%,
  100% {
    transform: translateY(0) rotate(0deg);
  }
  25% {
    transform: translateY(-15px) rotate(5deg);
  }
  50% {
    transform: translateY(0) rotate(0deg);
  }
  75% {
    transform: translateY(-15px) rotate(-5deg);
  }
}

@keyframes bounceAndGlow {
  0%,
  100% {
    transform: translateY(0) scale(1);
    filter: brightness(1) contrast(1);
  }
  50% {
    transform: translateY(-25px) scale(1.1);
    filter: brightness(1.2) contrast(1.1);
  }
}

@keyframes swingAndScale {
  0%,
  100% {
    transform: rotate(-10deg) scale(1);
  }
  50% {
    transform: rotate(10deg) scale(1.15);
  }
}

/* Responsive centering */
@media (max-width: 640px) {
  .tagWrapper:nth-child(1) {
    left: 15%;
  }
  .tagWrapper:nth-child(2) {
    left: 40%;
  }
  .tagWrapper:nth-child(3) {
    left: 65%;
  }

  .loadingContainer {
    min-height: 12rem;
  }
}

@keyframes twinkle {
  0%,
  100% {
    opacity: 0.2;
    transform: scale(0.5) rotate(0deg);
  }
  50% {
    opacity: 1;
    transform: scale(1) rotate(180deg);
  }
}

@keyframes popWave {
  0%,
  100% {
    transform: translateY(0) scale(0.8);
  }
  50% {
    transform: translateY(-12px) scale(1.2);
  }
}

@keyframes jumpWave {
  0%,
  100% {
    transform: translateY(0) scale(1) rotate(0deg);
  }
  50% {
    transform: translateY(-8px) scale(1.1) rotate(10deg);
  }
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .tagsContainer {
    height: 6rem;
  }

  .tag {
    width: 3rem;
    height: 3rem;
  }

  .tagIcon {
    width: 1.5rem;
    height: 1.5rem;
  }
}
