.container {
  position: fixed;
  inset: 0;
  overflow: hidden;
  background: black;
}

.scene {
  position: relative;
  width: 100%;
  height: 100%;
  perspective: 500px;
}

.skyBackground {
  /* position: absolute;
  inset: 0; */
  background: linear-gradient(165deg, #bfdbfe 0%, #60a5fa 100%);
  /* opacity: 1;
  transition: opacity 3s ease-in-out; */
}

.skyBackground.fading {
  opacity: 0;
}

.cloudLayer {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.cloud {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50px;
  animation: floatCloud linear infinite;
}

@keyframes floatCloud {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(-100%);
  }
}

@media (max-width: 768px) {
  .message {
    font-size: 1.6rem;
    bottom: 20%;
  }
}

.starfieldContainer {
  position: absolute;
  inset: 0;
  opacity: 0;
  transition: opacity 2s ease-in-out;
  background: radial-gradient(circle at 50% 50%, #000510 0%, #000000 100%);
  overflow: hidden;
}

.starfieldContainer.visible {
  opacity: 1;
}

.starfieldContainer.spinning {
  animation: spinField 12s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.starStreak {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 2px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
  transform-origin: center center;
  transition: all 0.5s ease-out;
}

.starStreak.warping {
  animation: streakWarp 1.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

/* Different phases get different animation durations and scales */
.starting .starStreak.warping {
  animation-duration: 2.5s;
  transform-origin: center center;
}

.building .starStreak.warping {
  animation-duration: 2s;
}

.intense .starStreak.warping {
  animation-duration: 1.75s;
}

.warping .starStreak.warping {
  animation-duration: 1.5s;
}

.finale .starStreak.warping {
  animation-duration: 1.25s;
}

/* .warpPortal.fading {
  opacity: 0;
} */

@keyframes spinField {
  0% {
    transform: rotate(0deg) scale(1);
  }
  10% {
    transform: rotate(12deg) scale(1.8);
  }
  20% {
    transform: rotate(28deg) scale(2.8);
  }
  100% {
    transform: rotate(720deg) scale(4800);
  }
}

/* Portal styles for different phases */
.warpPortal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 140px;
  height: 140px;
  background: #1e1b4b;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  opacity: 1;
  transition: all 5s cubic-bezier(0.4, 0, 0.2, 1);
}

.warpPortal.growing {
  transform: translate(-50%, -50%) scale(2);
  opacity: 1;
}

.warpPortal.warping {
  transform: translate(-50%, -50%) scale(20);
  opacity: 1;
  transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
}

.warpPortal.finale {
  animation: portalFinale 2s cubic-bezier(0.7, 0, 0.3, 1) forwards;
}

@keyframes portalFinale {
  0% {
    transform: translate(-50%, -50%) scale(20);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(150);
    opacity: 0;
  }
}

/* Center flash effect */
.centerFlash {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  background: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 20px 10px rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.centerFlash.active {
  opacity: 1;
}

/* Phase-specific container classes */
.starting .starfieldContainer {
  --streak-opacity: 0.3;
  --streak-scale: 0.5;
}

.building .starfieldContainer {
  --streak-opacity: 0.6;
  --streak-scale: 0.7;
}

.intense .starfieldContainer {
  --streak-opacity: 0.8;
  --streak-scale: 0.9;
}

.warping .starfieldContainer {
  --streak-opacity: 1;
  --streak-scale: 1;
}

/* Message styling */
.message {
  position: fixed;
  left: 50%;
  bottom: 15%;
  transform: translateX(-50%);
  color: white;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  z-index: 10;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.message.visible {
  opacity: 1;
}

@media (max-width: 768px) {
  .message {
    font-size: 1.5rem;
    bottom: 20%;
  }
}

@keyframes streakWarp {
  0% {
    opacity: 0;
    width: 0;
    transform: translate(-50%, -50%) rotate(var(--rotation));
  }
  10% {
    opacity: calc(var(--streak-opacity, 1) * var(--base-opacity, 1));
    width: calc(var(--streak-scale, 1) * 10px);
    transform: translate(-50%, -50%) rotate(var(--rotation));
  }
  100% {
    opacity: 0;
    width: var(--max-width, 400vmax);
    transform: translate(-50%, -50%) rotate(var(--rotation)) translateX(calc(var(--max-width, 400vmax) / 2));
  }
}

/* Add missing finale streak styles */
.starStreak.finaleStreak {
  --base-opacity: 1;
  --streak-scale: 1.5;
  animation: streakWarp 2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

/* Phase-specific container classes with thickness control */
.container.starting {
  --base-opacity: 0.35;
  --streak-scale: 0.5;
  --streak-thickness: 0.4;
  transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
}

.container.building {
  --base-opacity: 0.5;
  --streak-scale: 0.5;
  --streak-thickness: 0.5;
  transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
}

.container.intense {
  --base-opacity: 0.75;
  --streak-scale: 0.6;
  --streak-thickness: 0.7;
  transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
}

.container.warping {
  --base-opacity: 0.8;
  --streak-scale: 0.7;
  --streak-thickness: 1.2;
  transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
}

.container.finale {
  --base-opacity: 1;
  --streak-scale: 1;
  --streak-thickness: 2;
  transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
}

.starStreak {
  position: absolute;
  top: 50%;
  left: 50%;
  /* Remove fixed height and use calculated one */
  height: calc(var(--streak-thickness, 1) * var(--base-height, 1px));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
  transform-origin: center center;
  transition: all 0.5s ease-out;
}

.skyBackground {
  position: absolute;
  inset: 0;
  background: linear-gradient(165deg, #bfdbfe 0%, #60a5fa 100%);
  opacity: 0; /* Start hidden */
  transition: opacity 3s ease-in-out;
}

.skyBackground.visible {
  opacity: 1;
}

/* Update portal finale to create white flash */
/* @keyframes portalFinale {
  0% {
    transform: translate(-50%, -50%) scale(20);
    opacity: 1;
    background: #1e1b4b;
  }
  40% {
    transform: translate(-50%, -50%) scale(150);
    opacity: 1;
    background: white;
  }
  100% {
    transform: translate(-50%, -50%) scale(2000);
    opacity: 1;
    background: white;
  }
} */

/* Epic Gateway Styles */

.gatewayContainer {
  position: absolute;
  inset: 0;
  opacity: 0;
  transform: scale(0.9);
  transition: all 1s ease-in-out;
  overflow: hidden;
}

.gatewayContainer.visible {
  opacity: 1;
  transform: scale(1);
}

/* Central light beam
.centralBeam {
  position: relative;
  top: -20%;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 140%;
  overflow: hidden;
}

.beamCore {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 20%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.3) 80%, rgba(255, 255, 255, 0) 100%);
  animation: beamPulse 4s ease-in-out infinite;
}

.beamRays {
  position: absolute;
  width: 400%;
  height: 100%;
  left: -150%;
  background: repeating-linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2) 2px, transparent 4px);
  animation: rayRotate 20s linear infinite;
} */

/* Floating particles */
.particleContainer {
  position: absolute;
  inset: 0;
  pointer-events: none;
}

.particle {
  position: absolute;
  background: radial-gradient(circle at center, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0));
  border-radius: 50%;
  animation: particleFloat linear infinite;
}

/* Stairway structure */
.stairwayStructure {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) perspective(1000px) rotateX(25deg);
  transform-style: preserve-3d;
  width: 600px;
  height: 800px;
}

.pillar {
  position: absolute;
  bottom: 0;
  width: 40px;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.4));
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: repeating-linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.2) 50px, transparent 100px);
  }
}

.leftPillar {
  left: 40px;
  transform: translateZ(20px);
}

.rightPillar {
  right: 40px;
  transform: translateZ(20px);
}

/* Epic steps */
.epicStep {
  position: absolute;
  bottom: calc(var(--step-index) * 50px);
  left: 50%;
  width: calc(500px - (var(--step-index) * 25px));
  height: 40px;
  transform: translateX(-50%) translateZ(calc(var(--step-index) * 25px));
  transform-style: preserve-3d;
  opacity: 0;
  animation: epicStepAppear 1.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  animation-delay: var(--step-delay);
  transition: all 0.5s ease-out;
}

.epicStep::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  animation: shimmer 6s infinite alternate;
}

.stepSurface {
  position: absolute;
  inset: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.7));
  border: 2px solid rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
  transform-origin: bottom;
  transform: rotateX(-10deg);
}

.stepGlow {
  position: absolute;
  inset: -10px;
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.4), transparent 70%);
  filter: blur(5px);
  animation: glowPulse 2s ease-in-out infinite;
}

.stepSide {
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.3));
  transform-origin: top;
  transform: rotateX(90deg);
}

/* Ornate arch */
.archway {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 500px;
  height: 300px;
  opacity: 0;
  animation: archReveal 2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  animation-delay: 1s;
  transition: all 1s ease-out;
}

.archDetail {
  position: absolute;
  inset: 0;
  border: 4px solid rgba(255, 255, 255, 0.8);
  border-radius: 150px 150px 0 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1));
  &::before {
    content: '';
    position: absolute;
    inset: 10px;
    border: 2px solid rgba(255, 255, 255, 0.6);
    border-radius: 140px 140px 0 0;
  }
}

.archGlow {
  position: absolute;
  inset: -20px;
  border-radius: 170px 170px 0 0;
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.3), transparent 70%);
  filter: blur(10px);
  animation: archGlowPulse 4s ease-in-out infinite;
}

/* Animations */
@keyframes beamPulse {
  0%,
  100% {
    opacity: 0.8;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes rayRotate {
  0% {
    transform: translateX(-20%) rotate(0deg);
  }
  100% {
    transform: translateX(-20%) rotate(360deg);
  }
}

@keyframes particleFloat {
  0% {
    transform: translate(0, 100vh) scale(0);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translate(calc(var(--random-x, 0) * 100px), calc(var(--random-y, -200) * 1px)) scale(1);
    opacity: 0;
  }
}

@keyframes epicStepAppear {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateZ(calc(var(--step-index) * 25px)) translateY(100px);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateZ(calc(var(--step-index) * 25px)) translateY(0);
  }
}

@keyframes glowPulse {
  0%,
  100% {
    opacity: 0.1;
    transform: scale(1);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.1);
  }
}

@keyframes archReveal {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

@keyframes archGlowPulse {
  0%,
  100% {
    opacity: 0.3;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.1);
  }
}

/* Update message styling for gateway phase
.container.gateway .message {
  font-size: 2.5rem;
  font-weight: 300;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow:
    0 0 20px rgba(255, 255, 255, 0.8),
    0 0 40px rgba(255, 255, 255, 0.4);
  animation: messagePulse 3s ease-in-out infinite;
} */

.stairwayContainer {
  position: absolute;
  bottom: -100%;
  left: 50%;
  width: 100%;
  height: 100vh;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 1s ease-out;
  perspective: 1000px;
}

.stairwayContainer.visible {
  bottom: 0;
  opacity: 1;
}

.stair {
  position: absolute;
  bottom: calc(var(--index) * 60px);
  left: 50%;
  width: calc(600px - (var(--index) * 20px));
  height: 15px;
  background: rgba(255, 255, 255, 0.9);
  transform-origin: center;
  transform: translateX(-50%) translateZ(calc(var(--index) * 40px)) rotateX(5deg);
  opacity: 0;
  animation: stairAppear 0.5s ease-out forwards;
  animation-delay: calc(var(--index) * 0.1s);
  box-shadow:
    0 0 20px rgba(255, 255, 255, 0.3),
    0 10px 20px rgba(0, 0, 0, 0.1);
}

.stairGlow {
  position: absolute;
  inset: -5px;
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.4), transparent 70%);
  filter: blur(3px);
}

@keyframes stairAppear {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateZ(calc(var(--index) * 40px)) translateY(50px) rotateX(5deg);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateZ(calc(var(--index) * 40px)) translateY(0) rotateX(5deg);
  }
}

/* Enhanced effects and mobile optimization */

/* Mobile-first base styles */
/* .container {
  position: fixed;
  inset: 0;
  overflow: hidden;
  background: black;
  font-size: 16px;
} */

/* Sparkles and stars effect */
.sparkle {
  position: absolute;
  width: 4px;
  height: 4px;
  background: white;
  border-radius: 50%;
  animation: sparkleFloat 3s ease-in-out infinite;
  opacity: 0;
}

@keyframes sparkleFloat {
  0%,
  100% {
    transform: translate(0, 0) scale(0);
    opacity: 0;
  }
  50% {
    transform: translate(var(--x, 100px), var(--y, -100px)) scale(1);
    opacity: 1;
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Retro dialog box */
.dialogBox {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: min(90%, 500px); /* Responsive width */
  background: rgba(0, 0, 40, 0.95);
  border: 3px solid #fff;
  border-radius: 10px;
  padding: 20px;
  opacity: 0;
  transition: all 0.5s ease-out;
  box-shadow:
    0 0 20px rgba(255, 255, 255, 0.2),
    inset 0 0 20px rgba(255, 255, 255, 0.1);
}

.dialogBox.visible {
  opacity: 1;
  transform: translateX(-50%) translateY(0);
}

.dialogContent {
  color: white;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
}

.dialogHeader {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
}

.dialogIcon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  background: white;
  border-radius: 50%;
  animation: iconPulse 2s infinite;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .stairwayStructure {
    width: 100%;
    transform: translateX(-50%) perspective(1000px) rotateX(30deg) scale(0.7);
  }

  .epicStep {
    width: calc(300px - (var(--step-index) * 15px));
  }

  /* .centralBeam {
    width: 120px;
  } */

  .archway {
    width: 300px;
    height: 200px;
    top: 15%;
  }

  .message {
    font-size: 1.5rem;
    bottom: 25%;
  }

  .dialogBox {
    padding: 15px;
  }
}

/* Rainbow trail effect for steps */
.stepTrail {
  position: absolute;
  inset: 0;
  background: linear-gradient(90deg, #ff6b6b, #4ecdc4, #45b7d1, #96c93d);
  opacity: 0.1;
  filter: blur(5px);
  animation: rainbowShift 3s linear infinite;
}

@keyframes rainbowShift {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

/* Cute floating bubbles */
.bubble {
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(circle at 30% 30%, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.1));
  animation: bubbleFloat 120s ease-in-out infinite;
}

@keyframes bubbleFloat {
  0%,
  100% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(500px) scale(1.1);
  }
}

/* Enhanced arch glow */
.archGlow {
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: conic-gradient(from 0deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    animation: archRotate 10s linear infinite;
  }
}

@keyframes archRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Enhance central beam container */
.centralBeam {
  position: absolute;
  top: -20%;
  left: 50%;
  transform: translateX(-50%) scale(0);
  width: 110px;
  height: 140%;
  overflow: hidden;
  animation: beamEntrance 3s cubic-bezier(0.2, 0, 0.1, 1) forwards;
  transform-origin: center bottom;
}

@keyframes beamEntrance {
  0% {
    transform: translateX(-50%) scale(0) translateY(100%);
    filter: brightness(0);
  }
  10% {
    transform: translateX(-50%) scale(0.1) translateY(80%);
    filter: brightness(2);
  }
  30% {
    transform: translateX(-50%) scale(3) translateY(0);
    filter: brightness(5);
  }
  40% {
    transform: translateX(-50%) scale(0.8) translateY(0);
    filter: brightness(3);
  }
  45% {
    transform: translateX(-50%) scale(1.2) translateY(0);
    filter: brightness(4);
  }
  50% {
    transform: translateX(-50%) scale(1) translateY(0);
    filter: brightness(2);
  }
  60%,
  70%,
  80%,
  90% {
    transform: translateX(-50%) scale(1) translateY(0);
    filter: brightness(2);
  }
  100% {
    transform: translateX(-50%) scale(1) translateY(0);
    filter: brightness(1);
  }
}

/* Enhance beam core with dramatic effects */
.beamCore {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.3) 20%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.3) 80%, rgba(255, 255, 255, 0.1) 100%);
  animation:
    beamCorePulse 4s ease-in-out infinite,
    beamCoreEntrance 3s ease-out forwards;
  filter: drop-shadow(0 0 20px white);
}

@keyframes beamCoreEntrance {
  0% {
    opacity: 0;
    transform: scaleX(0);
    filter: brightness(0);
  }
  30% {
    opacity: 1;
    transform: scaleX(5);
    filter: brightness(3);
  }
  35% {
    transform: scaleX(0.8);
    filter: brightness(2);
  }
  40% {
    transform: scaleX(1.2);
    filter: brightness(2.5);
  }
  45% {
    transform: scaleX(0.9);
    filter: brightness(2);
  }
  50% {
    transform: scaleX(1);
    filter: brightness(1.5);
  }
  100% {
    opacity: 1;
    transform: scaleX(1);
    filter: brightness(1);
  }
}

/* Enhanced beam rays with dramatic entrance */
.beamRays {
  position: absolute;
  width: 400%;
  height: 100%;
  left: -150%;
  opacity: 0;
  background: repeating-linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2) 2px, transparent 4px);
  animation:
    rayRotate 20s linear infinite,
    rayEntrance 3s ease-out forwards;
  transform-origin: center;
}

@keyframes rayEntrance {
  0% {
    opacity: 0;
    transform: rotate(0deg) scale(0);
    filter: brightness(0);
  }
  30% {
    opacity: 0.3;
    transform: rotate(180deg) scale(2);
    filter: brightness(3);
  }
  40% {
    opacity: 0.6;
    transform: rotate(360deg) scale(1.5);
    filter: brightness(2);
  }
  50% {
    opacity: 0.8;
    transform: rotate(720deg) scale(1.2);
    filter: brightness(1.5);
  }
  60% {
    opacity: 1;
    transform: rotate(1080deg) scale(1);
    filter: brightness(1);
  }
  100% {
    opacity: 1;
    transform: rotate(1440deg) scale(1);
    filter: brightness(1);
  }
}

/* Add shockwave effect */
.centralBeam::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background: transparent;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: shockwave 3s cubic-bezier(0.2, 0, 0.1, 1) forwards;
  border: 2px solid rgba(255, 255, 255, 0.5);
}

@keyframes shockwave {
  0% {
    width: 10px;
    height: 10px;
    opacity: 1;
    border-width: 2px;
  }
  100% {
    width: 500px;
    height: 500px;
    opacity: 0;
    border-width: 1px;
  }
}

.gatewayContainer.visible {
  animation: screenShake 0.5s ease-in-out;
}

@keyframes screenShake {
  0%,
  100% {
    transform: scale(1) translate(0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: scale(1) translate(-5px, -5px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: scale(1) translate(5px, 5px);
  }
}

/* Enhance beam pulse with more dramatic effect */
@keyframes beamCorePulse {
  0%,
  100% {
    opacity: 0.1;
    transform: scale(0.7);
    filter: brightness(1);
  }
  50% {
    opacity: 1;
    transform: scale(1);
    filter: brightness(2);
  }
}

/* Add lightning flash effects */
.centralBeam::before {
  content: '';
  position: absolute;
  inset: 0;
  background: white;
  opacity: 0;
  animation: lightningFlash 3s ease-out;
}

@keyframes lightningFlash {
  0%,
  100% {
    opacity: 0;
  }
  30%,
  31% {
    opacity: 1;
  }
  32%,
  33% {
    opacity: 0;
  }
  34%,
  35% {
    opacity: 0.8;
  }
  36% {
    opacity: 0;
  }
}
